import { HttpClient } from "@angular/common/http";
import { ISubscription } from "../domain";
import { AdminBaseService } from "./admin-base.service";

export class AdminSubscriptions extends AdminBaseService {

    baseSubscriptionsURL = '/light/api/subscription';

    constructor(
        public http: HttpClient
    ) {
        super(http);
    }

    list(
        search?: string,
        page: number = 0,
        take: number = 10,
    ): Promise<ISubscription[]> {
        if (!page) page = 0;
        if (!take) take = 10;
        let q = `page=${page}&take=${take}`;

        if (search) {
            q += `&search=${search}`;
        }
        return this.getAsync(`${this.baseSubscriptionsURL}/list?${q}`);
    }

    count(search?: string,): Promise<number> {
        let q = '';
        if (search) {
            q += `&search=${search}`;
        }
        return this.getAsync(`${this.baseSubscriptionsURL}/count?${q}`);
    }

    create(subscription: ISubscription): Promise<ISubscription> {
        return this.putAsync(`${this.baseSubscriptionsURL}`, subscription);
    }

    update(subscription: ISubscription): Promise<ISubscription> {
        return this.postAsync(`${this.baseSubscriptionsURL}`, subscription);
    }

    delete(subscription: ISubscription): Promise<ISubscription> {
        return this.deleteAsync(`${this.baseSubscriptionsURL}/${subscription._id}`);
    }



}
