import { Directive, ElementRef, Input, OnChanges, Renderer2 } from "@angular/core";

@Directive({
    selector: '[highlight]'
})
export class HighlightDirective implements OnChanges {
    @Input() highlight: string = '';   // The text to search for
    @Input() searchTerm: string = '';     // The search term to highlight

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnChanges(): void {
        this.highlightText();
    }

    private highlightText() {
        const text = this.highlight || this.el.nativeElement.textContent;
        if (!text || !this.searchTerm) {
            return;
        }

        const regex = new RegExp(`(${this.searchTerm})`, 'gi');
        const highlightedText = text.replace(regex, `<span class="highlight">$1</span>`);
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', highlightedText);

    }
}
