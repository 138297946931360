import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { ColorPickerModule } from 'ngx-color-picker';

import { CheckboxControlComponent } from './form-controls/checkbox-control/checkbox-control.component';
import { DateSelectControlComponent } from './form-controls/date-select-control/date-select-control.component';
import { FileControlComponent } from './form-controls/file-control/file-control.component';
import { MultiTextSelectControlIconsComponent } from './form-controls/multi-text-select-control-icons/multi-text-select-control-icons.component';
import { MultiTextSelectControlComponent } from './form-controls/multi-text-select-control/multi-text-select-control.component';
import { NumericControlComponent } from './form-controls/numeric-control/numeric-control.component';
import { RadioControlComponent } from './form-controls/radio-control/radio-control.component';
import { TextAreaControlComponent } from './form-controls/text-area-control/text-area-control.component';
import { TextControlComponent } from './form-controls/text-control/text-control.component';
import { TextInputDropdownControlComponent } from './form-controls/text-input-dropdown/text-input-dropdown.component';
import { TextSelectControlIconsListComponent } from './form-controls/text-select-control-icons-list/text-select-control-icons-list.component';
import { TextSelectControlIconsComponent } from './form-controls/text-select-control-icons/text-select-control-icons.component';
import { TextSelectControlComponent } from './form-controls/text-select-control/text-select-control.component';
import { TextSelectPropertiesControlComponent } from './form-controls/text-select-properties-control/text-select-properties-control.component';
import { ValidationComponent } from './form-controls/validation/validation.component';
import { DecimalsValidator } from './form-controls/validators/decimals.validator';
import { DigitsValidator } from './form-controls/validators/digits.validator';
import { EmailOptionalValidator } from './form-controls/validators/email-optional.validator';
import { FileExtensionValidator } from './form-controls/validators/file-extension.validator';
import { FileSizeValidator } from './form-controls/validators/file-size.validator';
import { IntegerValidator } from './form-controls/validators/integer.validator';
import { MaxValidator } from './form-controls/validators/max.validator';
import { MinMaxRangeValidator } from './form-controls/validators/min-max-range.validator';
import { MinValidator } from './form-controls/validators/min.validator';
import { NumericValidator } from './form-controls/validators/numeric.validator';
import { PositiveRangeValidator } from './form-controls/validators/positive-range.validator';

import { CloseOnOutsideClickDirective } from './directives/close-on-outside-click.directive';
import { DetectResizeDirective } from './directives/detect-resize.directive';
import { ScrollableDirective } from './directives/scrollable.directive';

import { MainButtonComponent } from './components/main-button/main-button.component';
import { MainRemoveButtonComponent } from './components/main-remove-button/main-remove-button.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';

import { HelpButtonBandwidthComponent } from '../core/components/bandwith-profile-form/help-button-bandwidth/help-button-bandwidth.component';
import { HelpButtonComponent } from './components/help-button/help-button.component';
import { ColorPickerControlComponent } from './form-controls/color-picker-control/color-picker-control.component';
import { OpacityPickerControlComponent } from './form-controls/opacity-picker-control/opacity-picker-control.component';
import { UnitPipe } from './pipes/unit.pipe';

import { CloseButtonComponent } from './components/close-button/close-button.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { ExportTypeModalComponent } from './components/export-type-modal/export-type-modal.component';
import { SearchButtonComponent } from './components/search-button/search-button.component';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxViewerModule } from 'ngx-viewer';
import { AttachmentThumbnailComponent } from './components/attachment-thumbnail/attachment-thumbnail.component';
import { ConfirmChangesModalComponent } from './components/confirm-changes-modal/confirm-changes-modal.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { FavoriteCheckboxComponent } from './components/favorite-checkbox/favorite-checkbox.component';
import { LogCreateIncidentComponent } from './components/log-create-incident/log-create-incident.component';
import { LogCreatePointComponent } from './components/log-create-point/log-create-point.component';
import { SortButtonComponent } from './components/sort-button/sort-button.component';
import { UnreadCounterComponent } from './components/unread-counter/unread-counter.component';
import { HighlightDirective } from './directives/text-highlight';
import { RadioControlCoordinatesComponent } from './form-controls/radio-control-coordinates/radio-control-coordinates.component';

@NgModule({
    declarations: [
        LoadingSpinnerComponent,
        ScrollableDirective,
        CloseOnOutsideClickDirective,
        DetectResizeDirective,
        HighlightDirective,

        UnitPipe,

        RadioControlComponent,
        FileControlComponent,
        ValidationComponent,
        FileSizeValidator,
        FileExtensionValidator,
        NumericValidator,
        MinValidator,
        PositiveRangeValidator,
        MinMaxRangeValidator,
        DigitsValidator,
        EmailOptionalValidator,
        IntegerValidator,
        MaxValidator,
        DecimalsValidator,
        CheckboxControlComponent,
        MultiTextSelectControlComponent,
        MultiTextSelectControlIconsComponent,
        DateSelectControlComponent,
        TextControlComponent,
        TextSelectControlComponent,
        TextSelectPropertiesControlComponent,
        TextSelectControlIconsComponent,
        TextSelectControlIconsListComponent,
        TextInputDropdownControlComponent,
        TextAreaControlComponent,
        NumericControlComponent,
        MainButtonComponent,
        MainRemoveButtonComponent,
        HelpButtonComponent,
        HelpButtonBandwidthComponent,
        ColorPickerControlComponent,
        OpacityPickerControlComponent,
        SearchButtonComponent,
        CloseButtonComponent,
        ConfirmModalComponent,
        ExportTypeModalComponent,
        ConfirmChangesModalComponent,
        ErrorModalComponent,
        SortButtonComponent,
        FavoriteCheckboxComponent,
        RadioControlCoordinatesComponent,
        UnreadCounterComponent,
        DataTableComponent,
        AttachmentThumbnailComponent,
        LogCreateIncidentComponent,
        LogCreatePointComponent,
    ],
    exports: [
        CommonModule,
        FormsModule,
        FlexLayoutModule,
        InlineSVGModule,
        TranslateModule,
        LoadingSpinnerComponent,
        CloseOnOutsideClickDirective,
        ScrollableDirective,
        DetectResizeDirective,
        FormsModule,
        ReactiveFormsModule,
        UnitPipe,

        RadioControlComponent,
        FileControlComponent,
        ValidationComponent,
        FileSizeValidator,
        FileExtensionValidator,
        NumericValidator,
        MinValidator,
        PositiveRangeValidator,
        MinMaxRangeValidator,
        DigitsValidator,
        EmailOptionalValidator,
        IntegerValidator,
        MaxValidator,
        DecimalsValidator,
        CheckboxControlComponent,
        MultiTextSelectControlComponent,
        MultiTextSelectControlIconsComponent,
        DateSelectControlComponent,
        TextControlComponent,
        TextSelectControlComponent,
        TextSelectPropertiesControlComponent,
        TextSelectControlIconsComponent,
        TextSelectControlIconsListComponent,
        TextInputDropdownControlComponent,
        TextAreaControlComponent,
        NumericControlComponent,
        MainButtonComponent,
        MainRemoveButtonComponent,
        HelpButtonComponent,
        HelpButtonBandwidthComponent,
        ColorPickerControlComponent,
        OpacityPickerControlComponent,
        SearchButtonComponent,
        ConfirmModalComponent,
        ExportTypeModalComponent,
        CloseButtonComponent,
        SortButtonComponent,
        FavoriteCheckboxComponent,
        RadioControlCoordinatesComponent,
        UnreadCounterComponent,
        DataTableComponent,
        AttachmentThumbnailComponent,
        LogCreateIncidentComponent,
        LogCreatePointComponent,
        HighlightDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        InlineSVGModule,
        FlexLayoutModule,
        ColorPickerModule,
        TranslateModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        NgxViewerModule,
    ],
})
export class SharedModule { }
