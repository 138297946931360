import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdminAgent } from './admin-agent';
import { AdminGroups } from './admin-groups';
import { AdminJobs } from './admin-jobs';
import { AdminKeycloak } from './admin-keycloak';
import { AdminKibana } from './admin-kibana';
import { AdminPorts } from './admin-ports';
import { AdminReports } from './admin-reports';
import { AdminSubscriptions } from './admin-subscriptions';
import { AdminTools } from './admin-tools';
import { AdminTranslation } from './admin-translations';
import { AdminUsers } from './admin-users';

@Injectable({
    providedIn: 'root',
})
export class AdminService {
    keycloak: AdminKeycloak;
    kibana: AdminKibana;
    jobs: AdminJobs;
    users: AdminUsers;
    groups: AdminGroups;
    tools: AdminTools;
    reports: AdminReports;
    ports: AdminPorts;
    translation: AdminTranslation;
    adminAgent: AdminAgent;
    subscriptions: AdminSubscriptions;

    constructor(private http: HttpClient) {
        this.keycloak = new AdminKeycloak(this.http);
        this.kibana = new AdminKibana(this.http);
        this.jobs = new AdminJobs(this.http);
        this.users = new AdminUsers(this.http);
        this.groups = new AdminGroups(this.http);
        this.tools = new AdminTools(this.http);
        this.reports = new AdminReports(this.http);
        this.ports = new AdminPorts(this.http);
        this.translation = new AdminTranslation(this.http);
        this.adminAgent = new AdminAgent(this.http);
        this.subscriptions = new AdminSubscriptions(this.http);
    }
}
